import styles from './styles.module.css';

const PisoNotasGenericas = (props) => {
const { title, link, title1, link1, title2, link2, title3, link3} = props;
     return (
        <div className={`${styles["Pisogenericas-p"]}`}>
            {title &&  <div className={`${styles["contenedor-gral-tittulo-container"]}`}>
                <div className={`${styles["contenedor-left-titulo"]}`}>
                    <h2><a href={link}>{title}</a></h2>
                </div>
            
            <div className={`${styles["scroll-margin"]}`}>
                <nav className={`${styles["menu-el-scroll"]}`}>
                <ul>
                    {link1 && title1  &&  <li><a href={link1} >{title1}</a></li>}
                    {link2 && title2  &&  <li><a href={link2} >{title2}</a></li>}
                    {link3 && title3  &&  <li><a href={link3} >{title3}</a></li>}                
                </ul>
                </nav>
            </div>
            </div> 
            }
        <div className={`${styles["Pisogenericas-parent"]}`}> 
            <div className={`${styles['primer-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(1, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(5, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(6, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(7, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(8, "NotaContainerGrilla")}</div>
            </div>              
        </div>
        </div>
    )
}

export default PisoNotasGenericas;
